import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";

//SEN components
import theme from "../../theme";

import { TableColumn } from "../../interfaces/TableColumn";

const styles = () =>
	createStyles({
		root: {
			width: "100%",
			marginLeft: "20px",
		},
		rootMobile: {
			width: "100%",
		},
		white: {
			background: "white",
		},
		padding20: {
			padding: "20px",
		},
		w100: {
			width: "100%",
		},
		rounded: {
			borderRadius: "5px",
		},
		maxHeight: {
			maxHeight: "250px",
		},
		title: {
			flex: "1 1 100%",
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
		},
		table: {
			minWidth: 750,
		},
		visuallyHidden: {
			border: 0,
			clip: "rect(0 0 0 0)",
			height: 1,
			margin: -1,
			overflow: "hidden",
			padding: 0,
			position: "absolute",
			top: 20,
			width: 1,
		},
		inputBox: {
			borderRadius: "5px 5px 0px 0px",
			width: "100%",
			marginBottom: "10px",
		},
	});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator<Key extends keyof any>(order: "asc" | "desc", orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface SimpleTableProps {
	title: string;
	apiUrl: string;
	columns: TableColumn[];
	rows: any;
	loading: boolean;
	classes?: any;
	orderBy: string;
	order: "asc" | "desc";
	setCreate: () => void;
	setEdit: (id: string) => void;
	readOnly: boolean;
	blockCreate: boolean;
	isMobile: boolean;
	search: boolean;
	updateSearchQuery: (query: string) => void;
}

interface SimpleTableState {
	rowsPerPage: number;
	page: number;
	order: "asc" | "desc";
	orderBy: string;
}

class SimpleTable extends React.Component<SimpleTableProps, SimpleTableState> {
	constructor(props: SimpleTableProps) {
		super(props);
		this.state = { rowsPerPage: 10, page: 0, order: props.order, orderBy: props.orderBy };
		this.createSortHandler = this.createSortHandler.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
	}

	handleChangePage(event: unknown, newPage: number) {
		this.setState({ page: newPage });
	}

	handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
	}

	handleRequestSort(property: keyof any) {
		const isAsc = this.state.orderBy === property && this.state.order === "asc";
		this.setState({ orderBy: String(property), order: isAsc ? "desc" : "asc" });
	}

	createSortHandler(property: keyof any) {
		this.handleRequestSort(property);
	}

	render() {
		const { classes, title, columns, loading, rows, readOnly, blockCreate, isMobile, search } = this.props;
		const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, rows.length - this.state.page * this.state.rowsPerPage);
		return (
			<div className={!isMobile ? classes.root : classes.rootMobile}>
				<Paper className={classes.paper}>
					<Toolbar style={{ padding: "0px 12px" }}>
						<Typography className={classes.title} style={{ width: "10%" }} variant="h6" id="tableTitle" component="div">
							{title}
						</Typography>
						{!readOnly && !blockCreate && (
							<Tooltip title={`Add ${title}`}>
								<IconButton aria-label={`${title}`} onClick={() => this.props.setCreate()}>
									<AddIcon />
								</IconButton>
							</Tooltip>
						)}
					</Toolbar>
					<div style={{ padding: "0px 12px", width: "250px" }}>
						{search && <TextField label={"Search"} variant="filled" className={classes.inputBox} onChange={(event) => this.props.updateSearchQuery(event.target.value)} />}
					</div>

					<TableContainer>
						<Table className={classes.table} aria-labelledby={`${title}`} size={"medium"} aria-label="table">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell key={String(column.id)} align={"left"} padding={"default"} sortDirection={this.state.orderBy === column.id ? this.state.order : false}>
											<TableSortLabel
												active={this.state.orderBy === column.id}
												direction={this.state.orderBy === column.id ? this.state.order : "desc"}
												onClick={() => this.createSortHandler(column.id)}
											>
												{column.label}
												{this.state.orderBy === column.id ? (
													<span className={classes.visuallyHidden}>{this.state.order === "desc" ? "sorted descending" : "sorted ascending"}</span>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							{!loading && (
								<TableBody>
									{stableSort(rows, getComparator(this.state.order, this.state.orderBy))
										.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
										.map((row, index) => {
											return (
												<TableRow hover onClick={() => this.props.setEdit(String(row.id))} tabIndex={-1} key={String(row.id)}>
													{columns.map((column, cellIndex) => {
														if (column.type === "Timestamp") {
															const date = new Date(Number(row[String(column.id)]) * 1000).toLocaleString();
															return <TableCell align="left">{date}</TableCell>;
														} else if (column.type === "Date") {
															const date = new Date(String(row[String(column.id)])).toLocaleDateString();
															return <TableCell align="left">{date}</TableCell>;
														} else if (column.type === "ManyToMany") {
															return (
																<TableCell align="left">
																	{Object(row[String(column.referenceInputData!.keyInFetchResponse)]).map((item: Object) => (
																		<Chip
																			label={Object(item)[String(column.referenceInputData!.referenceName)]}
																			variant="outlined"
																			style={{ marginRight: "5px" }}
																		/>
																	))}
																</TableCell>
															);
														} else if (column.type === "ReferenceInput") {
															return (
																<TableCell align="left">
																	{Object(row[String(column.referenceInputData!.keyInFetchResponse)])[String(column.referenceInputData!.referenceName)]}
																</TableCell>
															);
														} else {
															return <TableCell align="left">{row[String(column.id)]}</TableCell>;
														}
													})}
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
						{loading && (
							<LinearProgress
								style={{
									width: "100%",
									height: "7px",
								}}
								color="primary"
							/>
						)}
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component="div"
						count={rows.length}
						rowsPerPage={this.state.rowsPerPage}
						page={this.state.page}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
		);
	}
}

export default withStyles(styles)(SimpleTable);
