import { SideBarItem } from "../../../interfaces/SideBarItem";
import TabIcon from "@material-ui/icons/Tab";

const NativeTab: SideBarItem = {
	name: "Native Tab",
	icon: <TabIcon />,
	adminPanelURL: "nativeTabs",
	fetchAllURL: "nativeTabs/all",
	fetchSingleURL: "nativeTabs/:id",
	updateURL: "nativeTabs/:id",
	deleteURL: "nativeTabs/:id",
	postURL: "nativeTabs",
	columns: [
		{ id: "title", label: "Title", type: "Text", isHeadCell: true, required: true },
		{ id: "sortOrder", label: "Sort Order", type: "Number", isHeadCell: true, required: true },
	],
	orderBy: "name",
	order: "asc",
	blockCreate: true,
	blockDelete: true,
};

export default NativeTab;
