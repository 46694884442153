import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";

//SEN components
import theme from "../theme";
import { AuthContext } from "../auth/authProvider/AuthContext";
import { logout } from "../dataProvider";

interface Props {
	window?: () => Window;
	children?: React.ReactElement;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			position: "fixed",
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
		marginLeft10: {
			marginLeft: "10px",
		},
		navLogo: {
			height: "30px",
			marginTop: "5px",
		},
		textCenter: {
			textAlign: "center",
		},
		textRight: {
			textAlign: "right",
		},
		fullWidth: {
			width: "100%",
		},

		secondaryBack: {
			background: theme.customColors.gradient.main,
		},
		pointer: {
			cursor: "pointer",
		},
		whiteButton: {
			background: "transparent",
			color: "white",
			border: "1px solid white",
			padding: "5px 35px",
			borderRadius: "3px",
		},
	})
);

export default function Nav(props: Props) {
	const classes = useStyles();
	const { authenticated, setAuthenticated, setRole } = React.useContext(AuthContext);
	const isAdmin = ["readOnlyAdmin", "fullAccessAdmin"].includes(String(localStorage.getItem("role")));
	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar className={[classes.secondaryBack].join(" ")} elevation={0}>
				<Toolbar>
					<div className={[classes.fullWidth].join(" ")}>
						<Link href={"/"}>
							<img className={classes.navLogo} src={process.env.REACT_APP_PRODUCT_IMAGE} alt={"Logo"} />
						</Link>
					</div>
					{isAdmin && (
						<div
							className={[classes.fullWidth, classes.textRight, classes.pointer].join(" ")}
							onClick={() => (window.location.pathname = "admin")}
						>
							Admin
						</div>
					)}
					{authenticated && (
						<div
							className={[classes.marginLeft10, classes.textRight, classes.pointer, classes.whiteButton].join(" ")}
							onClick={() => {
								setAuthenticated(logout());
								setRole("default");
							}}
						>
							Logout
						</div>
					)}
				</Toolbar>
			</AppBar>
			<Toolbar id="back-to-top-anchor" />
		</React.Fragment>
	);
}
