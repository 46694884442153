import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

//SEN components
import theme from "../theme";
import SimpleTable from "./tables/SimpleTable";
import SubMenu from "./sub-components/SubMenu";
import { SideBarItem } from "../interfaces/SideBarItem";
import { TableColumn } from "../interfaces/TableColumn";
import { get } from "../dataProvider";
import CreateView from "./AdminPageBuilder/CreateView";
import EditView from "./AdminPageBuilder/EditView";

import Users from "./Schema/Users";
import NativeTab from "./Schema/NativeTab";
import RemoteTab from "./Schema/RemoteTab";
import LiveVideoStream from "./Schema/LiveVideoStream";

const sideBar: SideBarItem[] = [LiveVideoStream, RemoteTab, NativeTab, Users]; //ADD YOUR ROUTES HERE

const styles = () =>
	createStyles({
		white: {
			background: "white",
		},
		padding20: {
			padding: "20px",
		},
		w100: {
			width: "100%",
		},
		rounded: {
			borderRadius: "5px",
		},
		maxHeight: {
			maxHeight: "250px",
		},
	});

interface AdminProps {
	classes?: any;
	history?: any;
}

interface AdminState {
	activePage: number;
	rows: any[];
	loading: boolean;
	view: string;
	editId: string;
	pageWidth: number;
	searchQuery: string;
}

class Admin extends React.Component<AdminProps, AdminState> {
	constructor(props: AdminProps) {
		super(props);
		this.state = { activePage: 0, rows: [], loading: true, view: "list", editId: "", pageWidth: window.innerWidth, searchQuery: "" };
		this.getLatestRows = this.getLatestRows.bind(this);
		this.updateTable = this.updateTable.bind(this);
		this.setCreate = this.setCreate.bind(this);
		this.setEdit = this.setEdit.bind(this);
		this.fetchCorrectPage = this.fetchCorrectPage.bind(this);
		this.updateSearchQuery = this.updateSearchQuery.bind(this);
	}

	updateSearchQuery(query: string) {
		this.setState({ searchQuery: query }, async () => {
			await this.componentDidMount();
		});
	}

	updateTable(page: number) {
		this.setState({ activePage: page, loading: true, view: "list" }, async () => {
			this.props.history.push(`/admin/${sideBar[this.state.activePage].name.toLowerCase()}`);
			await this.getLatestRows();
		});
	}

	async getLatestRows() {
		let url = `${process.env.REACT_APP_API_URL}/${sideBar[this.state.activePage].fetchAllURL}`;
		if (this.state.searchQuery.length) {
			url += "?search=" + this.state.searchQuery;
		}

		const newData = await get(url);
		this.setState({ rows: newData.data, loading: false });
	}

	async componentDidMount() {
		await this.fetchCorrectPage();
		await this.getLatestRows();

		window.onpopstate = async () => {
			await this.componentDidMount();
		};

		window.onresize = () => {
			this.setState({ pageWidth: window.innerWidth });
		};
	}

	fetchCorrectPage() {
		let path = window.location.pathname;
		const route = path.split("admin/")[1];
		let index = 0;

		if (route) {
			for (const column of sideBar) {
				if (route.includes(column.adminPanelURL)) {
					this.setState({ activePage: index });

					if (route.includes("edit")) {
						this.setState({ view: "edit", editId: route.split("edit/")[1] });
					} else if (route.includes("new")) {
						this.setState({ view: "create" });
					} else {
						this.setState({ activePage: index, loading: true, view: "list" });
					}
					break;
				}
				index++;
			}
		}
	}

	setCreate() {
		this.setState({ view: "create" }, () => {
			this.props.history.push(`/admin/${sideBar[this.state.activePage].name.toLowerCase()}/new/`);
		});
	}

	setEdit(id: string) {
		this.setState({ view: "edit", editId: id }, () => {
			this.props.history.push(`/admin/${sideBar[this.state.activePage].name.toLowerCase()}/edit/${this.state.editId}`);
		});
	}

	render() {
		const { classes } = this.props;
		const readOnly = localStorage.getItem("role") !== "fullAccessAdmin";
		const isMobile = this.state.pageWidth <= 1000;

		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Container maxWidth="xl" className={classes.padding20}>
					<Grid container>
						<SubMenu sideBar={sideBar} updateTable={this.updateTable} isMobile={isMobile} activePage={this.state.activePage} />
						<Grid container item xs={isMobile ? 12 : 9}>
							{this.state.view === "list" && (
								<SimpleTable
									readOnly={readOnly}
									title={sideBar[this.state.activePage].name}
									apiUrl={sideBar[this.state.activePage].fetchAllURL}
									columns={sideBar[this.state.activePage].columns.filter((col: TableColumn) => {
										return col.isHeadCell;
									})}
									orderBy={sideBar[this.state.activePage].orderBy}
									order={sideBar[this.state.activePage].order}
									loading={this.state.loading}
									rows={this.state.rows}
									setCreate={this.setCreate}
									setEdit={this.setEdit}
									search={sideBar[this.state.activePage].search || false}
									blockCreate={sideBar[this.state.activePage].blockCreate || false}
									isMobile={isMobile}
									updateSearchQuery={this.updateSearchQuery}
								/>
							)}
							{!readOnly && !(sideBar[this.state.activePage].blockCreate || false) ? (
								<>{this.state.view === "create" && <CreateView element={sideBar[this.state.activePage]} setEdit={this.setEdit} isMobile={isMobile} />}</>
							) : (
								<></>
							)}
							{this.state.view === "edit" && (
								<EditView
									id={this.state.editId}
									readOnly={readOnly}
									element={sideBar[this.state.activePage]}
									setEdit={this.setEdit}
									blockDelete={sideBar[this.state.activePage].blockDelete || false}
									isMobile={isMobile}
								/>
							)}
						</Grid>
					</Grid>
				</Container>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles)(Admin);
